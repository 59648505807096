@font-face {
  font-family: Winston;
  src: url(./assets/WinstonBold.ttf);
  font-weight: 600; }

@font-face {
  font-family: Winston;
  src: url(./assets/WinstonMedium.ttf);
  font-weight: 500; }

@font-face {
  font-family: Winston;
  src: url(./assets/WinstonThin.ttf);
  font-weight: 300; }

html, body {
  min-height: 100%;
  min-height: 100vh;
  position: relative;
  width: 100%;
  width: 100vw;
  overflow-x: hidden;
  font-family: 'Winston', sans-serif;
  background-color: transparent; }

::-webkit-scrollbar {
  width: 19px;
  height: 19px; }

::-webkit-scrollbar-thumb {
  height: 6px;
  border-radius: 15px;
  border: 6px solid rgba(0, 0, 0, 0);
  background-clip: padding-box;
  background-color: #D60018; }

::-webkit-scrollbar-track {
  background: transparent; }

::-webkit-scrollbar-button {
  width: 0;
  height: 0;
  display: none; }

::-webkit-scrollbar-corner {
  background-color: transparent; }

html, body, h1, h2, h3, h4, h5, h6, p, a, span, div {
  font-family: 'Winston', sans-serif; }

#root {
  overflow: hidden; }

a {
  text-decoration: none !important; }

.wrapper {
  width: 100%;
  padding-left: 33%;
  min-height: 95vh; }
  @media only screen and (max-width: 1023px) {
    .wrapper {
      padding-left: 0; } }
  .wrapper::before {
    content: ' ';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    background-image: url(./assets/images/background.png);
    height: 100%;
    background-size: 100% 100%;
    z-index: -1; }
    @media only screen and (max-width: 1023px) {
      .wrapper::before {
        height: 100%;
        background-image: url(./assets/images/mob-background.png); } }
  .wrapper header {
    -webkit-box-shadow: 0px 6px 10px 0px rgba(0, 0, 0, 0.04);
    -moz-box-shadow: 0px 6px 10px 0px rgba(0, 0, 0, 0.04);
    box-shadow: 0px 6px 10px 0px rgba(0, 0, 0, 0.04);
    background-color: transparent;
    width: 100%;
    height: 80px;
    border-radius: 10px;
    padding: 16px 18px;
    margin-bottom: 24px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: fixed;
    top: 15px;
    left: 320px;
    right: 0;
    width: calc(100vw - 340px);
    z-index: 99; }
    @media only screen and (max-width: 1023px) {
      .wrapper header {
        display: none; } }
  .wrapper .sidebar {
    z-index: 99;
    position: fixed;
    top: 0;
    left: 0;
    width: 33%;
    height: 100vh;
    -webkit-animation-name: example;
    /* Safari 4.0 - 8.0 */
    -webkit-animation-duration: 0.5s;
    /* Safari 4.0 - 8.0 */
    animation-name: example;
    animation-duration: 0.5s;
    overflow: hidden;
    /* Standard syntax */ }

@keyframes example {
  from {
    left: -100%; }
  to {
    left: 0; } }
    .wrapper .sidebar .logo {
      padding-top: 78px;
      padding-left: 69px; }
    .wrapper .sidebar .sidebar-text {
      padding-left: 69px;
      display: flex;
      flex-direction: column;
      padding-top: 95px; }
      .wrapper .sidebar .sidebar-text h1 {
        font-size: 82px;
        color: #FFFFFF;
        font-weight: 700; }
        @media only screen and (min-width: 1024px) and (max-width: 1400px) {
          .wrapper .sidebar .sidebar-text h1 {
            font-size: 42px; } }
        @media only screen and (min-width: 1401px) and (max-width: 1460px) {
          .wrapper .sidebar .sidebar-text h1 {
            font-size: 62px; } }
      .wrapper .sidebar .sidebar-text p {
        padding-top: 16px;
        font-size: 27px;
        color: #FFFFFF;
        font-weight: 100; }
        @media only screen and (min-width: 1024px) and (max-width: 1400px) {
          .wrapper .sidebar .sidebar-text p {
            font-size: 20px; } }
        @media only screen and (min-width: 1401px) and (max-width: 1460px) {
          .wrapper .sidebar .sidebar-text p {
            font-size: 24px; } }
    .wrapper .sidebar .sidebar-scroll {
      position: absolute;
      right: 0;
      top: 50%; }
    .wrapper .sidebar .social-networks {
      position: absolute;
      bottom: 0;
      left: 69px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      backdrop-filter: blur(4px) brightness(1.5);
      max-width: 85px;
      padding: 25px 29px 38px 29px; }
      .wrapper .sidebar .social-networks .fb {
        padding-bottom: 27px; }
      @media only screen and (min-width: 1024px) and (max-width: 1400px) {
        .wrapper .sidebar .social-networks {
          padding: 25px 20px 25px 24px; }
          .wrapper .sidebar .social-networks svg {
            width: 70%; }
          .wrapper .sidebar .social-networks .fb {
            padding-bottom: 15px; } }
    .wrapper .sidebar .menu {
      cursor: pointer;
      display: none; }
      @media only screen and (max-width: 768px) {
        .wrapper .sidebar .menu {
          display: block;
          position: absolute;
          top: 48px;
          left: 32px;
          z-index: 99; } }
    @media only screen and (max-width: 1023px) {
      .wrapper .sidebar {
        display: none; } }
  .wrapper .sidebar-hidden {
    left: -100%;
    -webkit-animation-name: example1;
    /* Safari 4.0 - 8.0 */
    -webkit-animation-duration: 0.5s;
    /* Safari 4.0 - 8.0 */
    animation-name: example1;
    animation-duration: 0.5s;
    /* Standard syntax */ }

@keyframes example1 {
  from {
    left: 0; }
  to {
    left: -100%; } }
    .wrapper .sidebar-hidden .menu {
      cursor: pointer;
      display: none; }
      @media only screen and (max-width: 768px) {
        .wrapper .sidebar-hidden .menu {
          display: block;
          position: fixed;
          top: 48px;
          left: 32px;
          z-index: 999; } }

.page-wrap {
  padding: 0px 0px 0 36px;
  max-height: calc(100vh - 250px);
  overflow-y: auto;
  overflow-x: hidden; }
  @media only screen and (max-width: 1023px) {
    .page-wrap {
      padding: 0;
      padding-left: 36px;
      padding-right: 40px;
      padding-bottom: 40px;
      max-height: calc(100vh - 175px); } }
  @media only screen and (max-width: 768px) {
    .page-wrap {
      padding-left: 0;
      padding-right: 0; } }

.play-game-wrap {
  padding: 0px 0px 0 0;
  max-height: 100vh;
  position: relative;
  overflow-y: auto;
  overflow-x: hidden;
  margin: 0;
  background-color: #0B2B75; }
  @media only screen and (max-width: 1023px) {
    .play-game-wrap {
      padding: 0;
      padding-left: 36px;
      padding-right: 40px;
      padding-bottom: 40px; } }
  @media only screen and (max-width: 768px) {
    .play-game-wrap {
      padding-left: 0;
      padding-right: 0; } }
  .play-game-wrap .performer {
    margin-top: 0 !important; }

.right-sidebar-wrap {
  padding: 36px 40px 0 36px; }
  @media only screen and (max-width: 768px) {
    .right-sidebar-wrap {
      padding: 40px 0px 0px 0px; } }

.input-field {
  background-color: #F1F9FF;
  border-radius: 25px;
  border: 2px solid #BCE0FD;
  padding: 13px 20px;
  width: 100%;
  font-weight: 500;
  font-size: 16px;
  color: #034EA2;
  outline: none; }

.label {
  font-size: 16px;
  font-weight: 700;
  color: #FFFFFF;
  margin-bottom: 7px; }

.field-wrap {
  display: flex;
  flex-direction: column;
  margin-bottom: 12px; }

.btn-wrap {
  display: flex;
  flex-direction: column;
  margin-top: 13px; }
  .btn-wrap h6 {
    font-size: 16px;
    font-weight: 700;
    color: #FFFFFF;
    margin-bottom: 25px; }

.btn-register-wrap {
  margin-top: 41px;
  display: flex;
  justify-content: center;
  align-items: center; }
  .btn-register-wrap button {
    width: 174px;
    padding: 0;
    padding: 11px 0; }

.button {
  background-color: #F1F9FF;
  border: 2px solid #BCE0FD;
  border-radius: 25px;
  font-size: 26px;
  font-weight: 500;
  width: 100%;
  color: #034EA2;
  padding: 11px 0;
  outline: none; }
  .button:focus {
    outline: none; }

.button2 {
  background-color: #034EA2;
  border: none;
  outline: none;
  width: 100%;
  border-radius: 25px;
  font-size: 26px;
  font-weight: 500;
  color: #FFFFFF;
  padding: 11px 0; }
  .button2:focus {
    outline: none; }

.login {
  margin-bottom: 42px; }
  .login h1 {
    font-size: 55px;
    font-weight: 700;
    color: #FFFFFF; }

.login-wrap {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 90vh;
  min-width: 90%;
  overflow-x: hidden; }

.register-wrap {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 90vh;
  width: 100%;
  padding: 0 110px;
  overflow-x: hidden; }
  .register-wrap .register {
    margin-bottom: 42px;
    display: flex;
    align-items: center;
    justify-content: center; }
    .register-wrap .register h1 {
      font-size: 55px;
      font-weight: 700;
      color: #FFFFFF; }
  .register-wrap .back {
    position: absolute;
    left: 0;
    top: 20px; }
  .register-wrap a {
    text-decoration: none;
    outline: none; }

.datepicker {
  background-color: #F1F9FF;
  border-radius: 25px;
  border: 2px solid #BCE0FD;
  padding: 13px 20px;
  width: 100%;
  font-weight: 500;
  font-size: 16px;
  color: #034EA2;
  outline: none; }

.check-option {
  display: flex;
  justify-content: space-between; }

.check-button {
  background-color: #F1F9FF;
  border: 2px solid #BCE0FD;
  border-radius: 25px;
  font-size: 16px;
  font-weight: 700;
  width: 100%;
  color: #034EA2;
  outline: none;
  height: 50px; }
  .check-button:focus {
    outline: none; }

.check-button-active {
  background-color: #034EA2;
  color: #FFFFFF;
  border: none;
  border-radius: 25px;
  font-size: 16px;
  font-weight: 700;
  width: 100%;
  outline: none;
  height: 50px; }
  .check-button-active:focus {
    outline: none; }

.check-distance {
  margin-right: 32px; }

.account-close-icon {
  position: absolute;
  right: 41px;
  top: 0; }

.mob-logo {
  display: none; }
  @media only screen and (max-width: 1023px) {
    .mob-logo {
      display: block;
      padding-bottom: 10px; }
      .mob-logo svg {
        width: 130px; } }

.page-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative; }
  .page-title h1 {
    font-size: 55px;
    font-weight: 700;
    color: #FFFFFF; }
    @media only screen and (max-width: 1023px) {
      .page-title h1 {
        font-size: 36px; } }
  .page-title .top-menu {
    display: flex;
    align-items: center; }
    .page-title .top-menu h3 {
      font-size: 25px;
      font-weight: 100;
      color: #FFFFFF;
      margin-right: 24px; }
      @media only screen and (max-width: 768px) {
        .page-title .top-menu h3 {
          display: none; } }
    .page-title .top-menu .account {
      margin-right: 40px; }
      @media only screen and (max-width: 768px) {
        .page-title .top-menu .account {
          margin-right: 10px;
          display: none; } }
    @media only screen and (max-width: 768px) {
      .page-title .top-menu {
        position: absolute;
        top: 0px;
        right: 0; } }
    @media only screen and (max-width: 1023px) {
      .page-title .top-menu {
        position: absolute;
        top: 20px;
        right: 0; } }

.page-header {
  margin-top: 41px; }
  @media only screen and (max-width: 1350px) {
    .page-header {
      display: none; } }
  .page-header ul {
    display: flex;
    justify-content: space-between;
    align-items: center;
    list-style-type: none;
    padding-left: 0; }
    .page-header ul li h6 {
      font-size: 23px;
      font-weight: 100;
      color: #FFFFFF; }
      @media only screen and (min-width: 1461px) and (max-width: 1610px) {
        .page-header ul li h6 {
          font-size: 20px; } }
      @media only screen and (min-width: 1351px) and (max-width: 1460px) {
        .page-header ul li h6 {
          font-size: 18px; } }
    .page-header ul li .active-page {
      font-weight: 700;
      background-color: #D60018;
      border-radius: 17px;
      padding: 2px 11px 2px 11px; }
  @media only screen and (max-width: 1023px) {
    .page-header {
      display: none; } }

.clean-btn {
  background-color: transparent;
  border: none;
  outline: none;
  margin: 0;
  padding: 0; }
  .clean-btn:focus {
    outline: none; }

.page-search {
  margin-top: 25px;
  padding-left: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center; }
  .page-search .nav-chevrons {
    display: flex;
    align-items: center;
    justify-content: center; }
    .page-search .nav-chevrons .left-chevron {
      margin-right: 25px; }
    .page-search .nav-chevrons .active-year {
      margin-left: 20px;
      margin-top: 8px; }
      .page-search .nav-chevrons .active-year h2 {
        font-size: 24px;
        font-weight: 400;
        color: #FFFFFF; }
  .page-search .search-input {
    align-items: center; }
    .page-search .search-input input {
      height: 35px;
      width: 236px;
      padding-left: 40px; }
    .page-search .search-input .search-btn {
      position: absolute;
      margin-left: -228px;
      margin-top: 5px;
      background-color: transparent;
      border: none;
      outline: none; }
      .page-search .search-input .search-btn:focus {
        outline: none; }

.news-section {
  margin-top: 40px; }

.news-page {
  width: 100%;
  max-height: 100%;
  background-color: #0B2B75;
  display: flex;
  border: none; }
  .news-page .news-page-image {
    width: 48%;
    height: 100%; }
    .news-page .news-page-image img {
      width: 100%;
      height: 100%;
      object-fit: cover; }
    @media only screen and (max-width: 768px) {
      .news-page .news-page-image {
        width: 100%; } }
  .news-page .overlay {
    position: absolute;
    top: 0;
    left: 15px;
    width: 48%;
    max-height: 100%;
    z-index: 999; }
    @media only screen and (max-width: 768px) {
      .news-page .overlay {
        width: 91%;
        height: 200px; } }
    .news-page .overlay::after {
      content: " ";
      position: absolute;
      top: 0;
      left: 60%;
      width: 40%;
      height: 100%;
      background-image: linear-gradient(to left, #0B2B75, transparent); }
      @media only screen and (max-width: 768px) {
        .news-page .overlay::after {
          top: 40%;
          left: 0;
          width: 100%;
          height: 60%;
          background-image: linear-gradient(to top, #0B2B75, transparent); } }
    .news-page .overlay::before {
      content: " ";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: #0143a1;
      mix-blend-mode: color; }
  .news-page .news-page-content {
    padding: 18px 49px 18px 28px;
    display: flex;
    flex-direction: column;
    margin-left: 48%;
    position: relative;
    overflow: hidden; }
    @media only screen and (max-width: 768px) {
      .news-page .news-page-content {
        margin-top: 200px;
        margin-left: 0;
        padding: 18px 29px 18px 28px; } }
    .news-page .news-page-content h3 {
      font-size: 42px;
      font-weight: 700;
      color: #FFFFFF;
      margin-bottom: 21px; }
    .news-page .news-page-content p {
      font-size: 20px;
      font-weight: 100;
      color: #FFFFFF; }
    .news-page .news-page-content .player {
      margin-bottom: 21px; }
      .news-page .news-page-content .player img {
        width: 100%;
        object-fit: cover; }
    .news-page .news-page-content .performances {
      display: flex;
      flex-direction: column; }
      .news-page .news-page-content .performances .table-title {
        background-color: #2674F7;
        padding: 7px 18px; }
        .news-page .news-page-content .performances .table-title h5 {
          font-size: 19px;
          font-weight: 700;
          color: #FFFFFF; }
      .news-page .news-page-content .performances .table-row {
        width: 100%;
        background-color: #31519B;
        display: flex;
        justify-content: space-between;
        padding: 11px 49px 11px 18px;
        border: none; }
        @media only screen and (max-width: 768px) {
          .news-page .news-page-content .performances .table-row {
            padding: 11px 5px; } }
        @media only screen and (max-width: 768px) {
          .news-page .news-page-content .performances .table-row svg {
            margin-right: 8px; } }
        .news-page .news-page-content .performances .table-row .row-info {
          display: flex;
          width: 100%; }
          .news-page .news-page-content .performances .table-row .row-info h3 {
            font-size: 15px;
            font-weight: 600;
            color: #FFFFFF;
            margin-bottom: 0; }
          .news-page .news-page-content .performances .table-row .row-info .col {
            padding: 0; }
  .news-page .news-social-networks {
    position: absolute;
    top: 0;
    right: 0;
    padding-top: 32px;
    padding-right: 49px;
    display: flex;
    align-items: center; }
    .news-page .news-social-networks svg {
      margin-left: 15px; }
    @media only screen and (max-width: 768px) {
      .news-page .news-social-networks {
        flex-direction: column;
        padding-top: 15px;
        padding-right: 30px;
        z-index: 999; }
        .news-page .news-social-networks svg {
          margin-left: 0px;
          margin-bottom: 15px;
          width: 20px;
          height: 20px; } }

.news-field-wrap:hover .active-border {
  outline: 2px solid #D60018; }

.news-field-wrap .active-padding {
  padding: 18px 49px 18px 28px; }
  @media only screen and (max-width: 768px) {
    .news-field-wrap .active-padding {
      padding-right: 10px; } }

.news-field-wrap .active-button {
  display: block; }

.news-field {
  width: 100%;
  margin-bottom: 18px;
  height: 257px;
  background-color: #0B2B75;
  display: flex;
  border: none; }
  .news-field .news-image {
    width: 48%; }
    .news-field .news-image img {
      width: 100%;
      height: 100%;
      object-fit: cover; }
  .news-field .overlay {
    position: absolute;
    top: 0;
    left: 15px;
    width: 48%;
    height: 257px;
    z-index: 999; }
    .news-field .overlay::after {
      content: " ";
      position: absolute;
      top: 0;
      left: 60%;
      width: 40%;
      height: 100%;
      background-image: linear-gradient(to left, #0B2B75, transparent); }
    .news-field .overlay::before {
      content: " ";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: #0143a1;
      mix-blend-mode: color; }
  .news-field .news-info {
    margin-left: 48%;
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    overflow: hidden; }
    .news-field .news-info h3 {
      font-size: 42px;
      font-weight: 700;
      color: #FFFFFF;
      margin-bottom: 21px;
      max-width: calc(100% - 70px); }
    .news-field .news-info p {
      font-size: 20px;
      font-weight: 100;
      color: #FFFFFF;
      margin-bottom: 0;
      height: 86px;
      overflow: hidden; }
    .news-field .news-info .news-more {
      display: flex;
      align-items: center;
      margin-top: 15px; }
      .news-field .news-info .news-more svg {
        margin-right: 13px; }
      .news-field .news-info .news-more h4 {
        font-size: 16px;
        font-weight: 100;
        color: #FFFFFF; }
    @media only screen and (max-width: 768px) {
      .news-field .news-info h3 {
        font-size: 30px;
        margin-bottom: 10px; }
      .news-field .news-info p {
        font-size: 16px;
        height: 90px; }
      .news-field .news-info .news-more h4 {
        display: none; } }
  .news-field .news-date {
    position: absolute;
    display: flex;
    align-items: center;
    right: 35px;
    top: 20px; }
    .news-field .news-date h6 {
      font-size: 13px;
      font-weight: 700;
      color: #FFFFFF; }
    .news-field .news-date svg {
      margin-left: 11px; }
    @media only screen and (max-width: 768px) {
      .news-field .news-date {
        display: none; }
        .news-field .news-date svg {
          display: none; } }

.active-padding {
  padding: 55px 49px 50px 28px; }

.home-content {
  margin-top: 40px; }

.gallery {
  margin-top: 40px;
  overflow-x: hidden; }
  .gallery .gallery-field-wrap:hover .active-button {
    display: block; }
  .gallery .gallery-field-wrap:hover .active {
    outline: 2px solid #D60018; }
    .gallery .gallery-field-wrap:hover .active .click-for-more {
      position: absolute;
      top: 40%;
      right: -22px;
      z-index: 999; }
  .gallery .gallery-field {
    position: relative;
    margin-bottom: 18px;
    margin-top: 2px;
    display: flex;
    flex-direction: column;
    height: 257px;
    border: none; }
    .gallery .gallery-field img {
      width: 100%;
      height: 100%;
      object-fit: cover; }
    .gallery .gallery-field .active-button {
      display: none; }
    .gallery .gallery-field .field-info {
      width: 100%;
      height: 257px;
      position: relative;
      margin-top: -257px;
      z-index: 999;
      padding: 20px 107px 15px 23px; }
      .gallery .gallery-field .field-info h3 {
        font-size: 36px;
        font-weight: 700;
        color: #FFFFFF;
        height: 180px;
        overflow: hidden; }
      @media only screen and (max-width: 1460px) {
        .gallery .gallery-field .field-info {
          padding-right: 50px; } }
    .gallery .gallery-field .field-preview {
      display: flex;
      align-items: center;
      justify-content: space-between;
      background-color: transparent;
      padding: 0px 21px 0px 23px;
      margin-top: -35px;
      border: none;
      outline: none;
      z-index: 999;
      width: 100%; }
      .gallery .gallery-field .field-preview:focus {
        outline: none; }
      .gallery .gallery-field .field-preview h6 {
        font-size: 20px;
        font-weight: 700;
        color: #FFFFFF;
        z-index: 999; }
      .gallery .gallery-field .field-preview svg {
        z-index: 999; }
  .gallery .overlay {
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
    height: 257px;
    z-index: 99; }
    .gallery .overlay::before {
      content: " ";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: #0143a1;
      mix-blend-mode: color; }
    .gallery .overlay::after {
      content: " ";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: #042b63;
      mix-blend-mode: multiply; }
  .gallery .overlay2 {
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
    height: 390px;
    z-index: 99; }
    .gallery .overlay2::before {
      content: " ";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: #0143a1;
      backdrop-filter: blur(3px) brightness(0.5);
      mix-blend-mode: color; }
    .gallery .overlay2::after {
      content: " ";
      position: absolute;
      top: 40%;
      left: 0;
      width: 100%;
      height: 60%;
      background-image: linear-gradient(to top, #0B2B75, transparent); }

.home-news-field {
  cursor: pointer; }
  .home-news-field .home-field {
    position: relative;
    margin-bottom: 18px;
    display: flex;
    flex-direction: column; }
    .home-news-field .home-field img {
      width: 100%;
      height: 532px;
      object-fit: cover; }
    .home-news-field .home-field .field-preview {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 10px 0 0px 0;
      background: rgba(4, 43, 99, 0.73);
      margin-top: -35px;
      border: none;
      outline: none;
      z-index: 999; }
      .home-news-field .home-field .field-preview:focus {
        outline: none; }
      .home-news-field .home-field .field-preview h6 {
        position: absolute;
        left: 38px;
        font-size: 20px;
        font-weight: 700;
        color: #FFFFFF; }
      .home-news-field .home-field .field-preview svg {
        margin-top: -6px;
        z-index: 999; }
        @media only screen and (max-width: 768px) {
          .home-news-field .home-field .field-preview svg {
            display: none; } }
    .home-news-field .home-field .field-preview-overlay {
      position: relative;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 99; }
      .home-news-field .home-field .field-preview-overlay::before {
        content: " ";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(4, 43, 99, 0.73);
        mix-blend-mode: multiply; }
    .home-news-field .home-field .field-info {
      width: 100%;
      height: 532px;
      position: relative;
      margin-top: -532px;
      z-index: 999; }
      .home-news-field .home-field .field-info:hover {
        outline: 2px solid #D60018; }
      .home-news-field .home-field .field-info .info-text {
        display: flex;
        flex-direction: column;
        padding: 27px 27px;
        padding-top: 27px;
        padding-left: 27px;
        padding-right: 40%;
        height: 440px;
        overflow: hidden; }
        @media only screen and (max-width: 768px) {
          .home-news-field .home-field .field-info .info-text {
            height: 490px; } }
        .home-news-field .home-field .field-info .info-text h3 {
          font-size: 42px;
          font-weight: 700;
          color: #FFFFFF;
          margin-bottom: 36px; }
        .home-news-field .home-field .field-info .info-text p {
          font-size: 25px;
          font-weight: 100;
          color: #FFFFFF; }
      .home-news-field .home-field .field-info svg {
        position: absolute;
        right: -22px;
        top: 106px; }
  .home-news-field .news-more {
    position: relative;
    display: flex; }
    .home-news-field .news-more svg {
      margin-right: 14px; }
    .home-news-field .news-more h4 {
      font-size: 16px;
      font-weight: 100;
      color: #FFFFFF; }
  .home-news-field .field-info-overlay {
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
    height: 532px;
    z-index: 99; }
    .home-news-field .field-info-overlay::after {
      content: " ";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba(4, 43, 99, 0.73);
      mix-blend-mode: multiply; }
  .home-news-field .overlay {
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
    height: 532px;
    z-index: 99; }
    .home-news-field .overlay::before {
      content: " ";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: #0143a1;
      mix-blend-mode: color; }
  .home-news-field:hover .field-preview {
    display: none; }

.opacity svg {
  opacity: 0.4; }

.event-field .home-field {
  position: relative;
  margin-bottom: 18px;
  display: flex;
  flex-direction: column;
  height: 257px; }
  .event-field .home-field img {
    width: 100%;
    height: 100%;
    object-fit: cover; }
  .event-field .home-field .field-preview {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px 0 0px 0;
    background: rgba(4, 43, 99, 0.73);
    margin-top: -35px;
    border: none;
    outline: none;
    z-index: 999; }
    .event-field .home-field .field-preview:focus {
      outline: none; }
    .event-field .home-field .field-preview h6 {
      position: absolute;
      left: 38px;
      font-size: 20px;
      font-weight: 700;
      color: #FFFFFF;
      height: 20px;
      width: 35%;
      overflow: hidden; }
    .event-field .home-field .field-preview svg {
      margin-top: -6px;
      z-index: 999; }
      @media only screen and (max-width: 768px) {
        .event-field .home-field .field-preview svg {
          display: none; } }
  .event-field .home-field .field-preview-overlay {
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 99; }
    .event-field .home-field .field-preview-overlay::before {
      content: " ";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba(4, 43, 99, 0.73);
      mix-blend-mode: multiply; }
  .event-field .home-field .field-info {
    display: none;
    width: 100%;
    height: 257px;
    position: relative;
    outline: 2px solid #D60018;
    margin-top: -257px;
    z-index: 999; }
    .event-field .home-field .field-info .info-text {
      display: flex;
      flex-direction: column;
      padding: 15px 50px 15px 23px;
      height: 230px;
      overflow: hidden; }
      .event-field .home-field .field-info .info-text h3 {
        font-size: 42px;
        font-weight: 700;
        color: #FFFFFF;
        margin-bottom: 21px; }
      .event-field .home-field .field-info .info-text p {
        font-size: 20px;
        font-weight: 100;
        color: #FFFFFF; }
    .event-field .home-field .field-info svg {
      position: absolute;
      right: -22px;
      top: 106px; }

.event-field .field-info-overlay {
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  height: 257px;
  z-index: 99; }

.event-field .overlay {
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  height: 257px;
  z-index: 99; }
  .event-field .overlay::before {
    content: " ";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #0143a1;
    mix-blend-mode: color; }

.event-field:hover .field-preview {
  display: none; }

.event-field:hover .field-info {
  display: block; }

.event-field:hover .field-info-overlay::after {
  content: " ";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(4, 43, 99, 0.73);
  mix-blend-mode: multiply; }

.home-event-field .home-event-field {
  position: relative;
  margin-bottom: 18px;
  display: flex;
  flex-direction: column;
  height: 257px;
  cursor: pointer; }
  .home-event-field .home-event-field img {
    width: 100%;
    height: 100%;
    min-height: 257px;
    object-fit: cover; }
  .home-event-field .home-event-field .field-preview {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px 0 0px 0;
    background: rgba(4, 43, 99, 0.73);
    margin-top: -35px;
    border: none;
    outline: none;
    z-index: 999; }
    .home-event-field .home-event-field .field-preview:focus {
      outline: none; }
    .home-event-field .home-event-field .field-preview h6 {
      position: absolute;
      left: 38px;
      font-size: 20px;
      font-weight: 700;
      color: #FFFFFF;
      height: 20px;
      width: 35%;
      overflow: hidden; }
    .home-event-field .home-event-field .field-preview svg {
      margin-top: -6px;
      z-index: 999; }
      @media only screen and (max-width: 768px) {
        .home-event-field .home-event-field .field-preview svg {
          display: none; } }
  .home-event-field .home-event-field .field-preview-overlay {
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 99; }
    .home-event-field .home-event-field .field-preview-overlay::before {
      content: " ";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba(4, 43, 99, 0.73);
      mix-blend-mode: multiply; }
  .home-event-field .home-event-field .field-info {
    display: none;
    width: 100%;
    height: 257px;
    position: relative;
    outline: 2px solid #D60018;
    margin-top: -257px;
    z-index: 999; }
    .home-event-field .home-event-field .field-info .info-text {
      display: flex;
      flex-direction: column;
      padding: 15px 50px 15px 23px;
      height: 230px;
      overflow: hidden; }
      .home-event-field .home-event-field .field-info .info-text h3 {
        font-size: 42px;
        font-weight: 700;
        color: #FFFFFF;
        margin-bottom: 21px; }
      .home-event-field .home-event-field .field-info .info-text p {
        font-size: 20px;
        font-weight: 100;
        color: #FFFFFF; }
    .home-event-field .home-event-field .field-info svg {
      position: absolute;
      right: -22px;
      top: 106px; }

.home-event-field .field-info-overlay {
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  height: 257px;
  z-index: 99; }

.home-event-field .overlay {
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  height: 257px;
  z-index: 99; }
  .home-event-field .overlay::before {
    content: " ";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #0143a1;
    mix-blend-mode: color; }

.home-event-field:hover .field-preview {
  display: none; }

.home-event-field:hover .field-info {
  display: block; }

.home-event-field:hover .field-info-overlay::after {
  content: " ";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(4, 43, 99, 0.73);
  mix-blend-mode: multiply; }

.menu-closed {
  display: none; }

.account-closed {
  display: none; }

.account-preview-closed {
  display: none; }

.edit-profile-closed {
  display: none; }

.change-password-closed {
  display: none; }

.menu-open {
  background-color: transparent;
  backdrop-filter: blur(26px) brightness(0.8);
  height: 100%;
  width: 368px;
  padding-top: 50px;
  overflow-y: auto;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 99999;
  -webkit-animation-name: example;
  /* Safari 4.0 - 8.0 */
  -webkit-animation-duration: 0.5s;
  /* Safari 4.0 - 8.0 */
  animation-name: example;
  animation-duration: 0.5s; }

@keyframes example {
  from {
    right: -100%; }
  to {
    right: 0; } }
  .menu-open .top-menu {
    margin-left: 37px;
    display: flex;
    align-items: center;
    justify-content: flex-end; }
    .menu-open .top-menu h3 {
      font-size: 25px;
      font-weight: 100;
      color: #FFFFFF;
      margin-right: 24px; }
    .menu-open .top-menu .account {
      margin-right: 40px; }
  .menu-open .menu-options {
    margin-top: 62px; }
    .menu-open .menu-options ul {
      display: flex;
      flex-direction: column;
      align-items: left;
      list-style-type: none;
      padding-left: 52px; }
      .menu-open .menu-options ul li {
        margin-bottom: 48px; }
        .menu-open .menu-options ul li h6 {
          font-size: 26px;
          font-weight: 100;
          color: #FFFFFF; }
        .menu-open .menu-options ul li .active-page {
          font-size: 34px;
          font-weight: 700;
          background-color: #D60018;
          padding: 2px 11px 8px 62px;
          margin-left: -66px; }

.account-open {
  background-color: transparent;
  backdrop-filter: blur(26px) brightness(0.8);
  height: 100%;
  width: 368px;
  padding-top: 77px;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 99999;
  -webkit-animation-name: example;
  /* Safari 4.0 - 8.0 */
  -webkit-animation-duration: 0.5s;
  /* Safari 4.0 - 8.0 */
  animation-name: example;
  animation-duration: 0.5s; }

@keyframes example {
  from {
    right: -100%; }
  to {
    right: 0; } }
  .account-open .account-settings {
    margin-left: 16px;
    display: flex;
    flex-direction: column;
    position: relative;
    max-height: calc(100vh - 77px);
    overflow-y: auto; }
    .account-open .account-settings .account-image {
      background-color: transparent;
      width: 137px;
      height: 135px;
      position: relative; }
      .account-open .account-settings .account-image img {
        border-radius: 50%;
        height: 100%;
        width: 100%;
        object-fit: cover; }
      .account-open .account-settings .account-image .image-btn {
        display: none; }
      .account-open .account-settings .account-image:hover .image-btn {
        display: block; }
      .account-open .account-settings .account-image .image-btn {
        position: relative;
        width: 100%;
        height: 50%;
        background-color: rgba(214, 0, 24, 0.3);
        border: none;
        outline: none;
        color: #FFFFFF;
        font-size: 22px;
        font-weight: 500;
        margin-top: -50%; }
    .account-open .account-settings .account-info {
      margin-top: 17px; }
      .account-open .account-settings .account-info h3 {
        color: #FFFFFF;
        font-size: 58px;
        font-weight: 100; }
      .account-open .account-settings .account-info h6 {
        color: #FFFFFF;
        font-size: 22px;
        font-weight: 500; }
      .account-open .account-settings .account-info .other-info {
        margin-top: 70px; }
        .account-open .account-settings .account-info .other-info .info {
          display: flex;
          align-items: center; }
          .account-open .account-settings .account-info .other-info .info h5 {
            color: #FFFFFF;
            font-size: 22px;
            font-weight: 500; }
          .account-open .account-settings .account-info .other-info .info span {
            color: #FFFFFF;
            font-size: 22px;
            font-weight: 100; }
    .account-open .account-settings .account-btns {
      margin-top: 70px;
      padding-right: 30px; }
      .account-open .account-settings .account-btns .edit-btns {
        display: flex;
        flex-direction: column; }
        .account-open .account-settings .account-btns .edit-btns .bottom26 {
          margin-bottom: 26px; }
        .account-open .account-settings .account-btns .edit-btns .edit-btn {
          background-color: #F1F9FF;
          border: 2px solid #BCE0FD;
          border-radius: 25px;
          height: 40px;
          font-size: 23px;
          font-weight: 500;
          color: #034EA2;
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 0 17px;
          outline: none; }
          .account-open .account-settings .account-btns .edit-btns .edit-btn:focus {
            outline: none; }
          .account-open .account-settings .account-btns .edit-btns .edit-btn .button-left svg {
            margin-right: 10px; }
      .account-open .account-settings .account-btns .delete-btns {
        display: flex;
        justify-content: space-between;
        margin-top: 285px;
        padding-bottom: 50px; }
        .account-open .account-settings .account-btns .delete-btns .delete-btn {
          background-color: #F1F9FF;
          border: 2px solid #BCE0FD;
          border-radius: 25px;
          height: 40px;
          font-size: 23px;
          font-weight: 500;
          color: #034EA2;
          padding: 0 17px;
          outline: none; }
          .account-open .account-settings .account-btns .delete-btns .delete-btn:focus {
            outline: none; }
        .account-open .account-settings .account-btns .delete-btns .logout-btn {
          background-color: #D60018;
          border: none;
          border-radius: 25px;
          height: 40px;
          font-size: 23px;
          font-weight: 500;
          color: #F1F9FF;
          padding: 0 17px;
          outline: none; }
          .account-open .account-settings .account-btns .delete-btns .logout-btn:focus {
            outline: none; }
  .account-open .edit-profile-open {
    margin-top: 70px;
    padding-right: 30px;
    padding-bottom: 50px; }
    .account-open .edit-profile-open .field-wrap {
      margin-bottom: 17px; }
    .account-open .edit-profile-open input {
      height: 40px;
      padding: 7px 15px;
      font-size: 23px;
      font-weight: 500; }
    .account-open .edit-profile-open button {
      height: 40px; }
    .account-open .edit-profile-open .edit-profile-btn-wrap {
      margin-top: 140px; }
      .account-open .edit-profile-open .edit-profile-btn-wrap .edit-profile-button {
        background-color: #D60018;
        border: none;
        border-radius: 25px;
        height: 40px;
        font-size: 21px;
        font-weight: 500;
        color: #F1F9FF;
        padding: 0 17px;
        outline: none; }
        .account-open .edit-profile-open .edit-profile-btn-wrap .edit-profile-button:focus {
          outline: none; }
  .account-open .change-password-open {
    margin-top: 70px;
    padding-right: 30px;
    padding-bottom: 50px; }
    .account-open .change-password-open .field-wrap {
      margin-bottom: 17px; }
    .account-open .change-password-open input {
      height: 40px;
      padding: 7px 15px;
      font-size: 23px;
      font-weight: 500; }
    .account-open .change-password-open .edit-profile-btn-wrap {
      margin-top: 140px; }
      .account-open .change-password-open .edit-profile-btn-wrap .edit-profile-button {
        background-color: #D60018;
        border: none;
        border-radius: 25px;
        height: 40px;
        font-size: 21px;
        font-weight: 500;
        color: #F1F9FF;
        padding: 0 17px;
        outline: none; }
        .account-open .change-password-open .edit-profile-btn-wrap .edit-profile-button:focus {
          outline: none; }

.event-performers {
  position: relative;
  width: 100%;
  max-height: 100%;
  background-color: #0B2B75;
  padding: 0 42px 32px 28px;
  z-index: 999; }
  @media only screen and (max-width: 768px) {
    .event-performers {
      margin-left: auto; } }
  .event-performers .calendar-detail {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px; }
    .event-performers .calendar-detail .button-gallery {
      background-color: #034EA2;
      border: none;
      outline: none;
      width: 100%;
      border-radius: 23px;
      font-size: 23px;
      font-weight: 700;
      color: #FFFFFF;
      padding: 13px 32px; }
      .event-performers .calendar-detail .button-gallery:focus {
        outline: none; }
      @media only screen and (max-width: 768px) {
        .event-performers .calendar-detail .button-gallery {
          padding: 13px 12px;
          font-size: 18px; } }
    .event-performers .calendar-detail h2 {
      font-size: 30px;
      font-weight: 700;
      color: #FFFFFF;
      margin-bottom: 16px; }
    @media only screen and (max-width: 768px) {
      .event-performers .calendar-detail {
        padding-bottom: 40px; } }

@media only screen and (max-width: 768px) {
  .mob-padding {
    padding: 0; } }

.performer {
  position: relative;
  margin-top: 40px;
  width: 100%;
  min-height: 390px;
  max-height: 100%;
  background-color: #0B2B75;
  overflow-y: auto; }
  @media only screen and (max-width: 768px) {
    .performer {
      margin-left: auto; } }
  .performer .performer-info {
    position: relative;
    display: flex;
    flex-direction: column;
    padding: 32px 42px 32px 28px;
    z-index: 999; }
    @media only screen and (max-width: 768px) {
      .performer .performer-info {
        padding: 52px 11px 32px 11px; } }
    .performer .performer-info h1 {
      font-size: 57px;
      font-weight: 700;
      color: #FFFFFF;
      margin-bottom: 23px; }
    .performer .performer-info .marginb42 {
      margin-bottom: 42px; }
    .performer .performer-info .marginb55 {
      margin-bottom: 55px; }
    .performer .performer-info .calendar-detail {
      display: flex;
      justify-content: space-between;
      align-items: center; }
      .performer .performer-info .calendar-detail .button-gallery {
        background-color: #034EA2;
        border: none;
        outline: none;
        width: 100%;
        border-radius: 23px;
        font-size: 23px;
        font-weight: 700;
        color: #FFFFFF;
        padding: 13px 32px; }
        .performer .performer-info .calendar-detail .button-gallery:focus {
          outline: none; }
    .performer .performer-info .info {
      display: flex;
      flex-direction: column;
      margin-bottom: 13px; }
      .performer .performer-info .info h5 {
        font-size: 20px;
        font-weight: 700;
        color: #FFFFFF;
        margin-bottom: 0; }
      .performer .performer-info .info span {
        font-size: 20px;
        font-weight: 100;
        color: #FFFFFF; }
    .performer .performer-info h2 {
      font-size: 30px;
      font-weight: 700;
      color: #FFFFFF;
      margin-bottom: 16px; }
    .performer .performer-info p {
      font-size: 20px;
      font-weight: 100;
      color: #FFFFFF;
      margin-bottom: 13px; }
    .performer .performer-info .performances {
      display: flex;
      align-items: center;
      margin-bottom: 20px; }
      .performer .performer-info .performances h4 {
        font-size: 20px;
        font-weight: 400;
        color: #FFFFFF;
        margin-bottom: 0; }
      .performer .performer-info .performances table {
        display: flex;
        flex-direction: column; }
        .performer .performer-info .performances table tr {
          padding-bottom: 16px;
          height: 42px; }
          .performer .performer-info .performances table tr td {
            padding-right: 30px; }
            .performer .performer-info .performances table tr td img {
              width: 26px;
              height: 26px;
              object-fit: cover; }
    .performer .performer-info .video {
      margin-top: 20px;
      display: flex;
      flex-direction: column; }
      .performer .performer-info .video .players {
        display: flex; }
        .performer .performer-info .video .players .player {
          width: 50%; }
        .performer .performer-info .video .players .player-margin {
          margin-right: 45px; }
        .performer .performer-info .video .players img {
          width: 100%;
          height: 100%;
          object-fit: cover; }
  .performer .performer-social-networks {
    position: absolute;
    top: 0;
    right: 0;
    padding-top: 32px;
    padding-right: 48px;
    display: flex;
    align-items: center; }
    .performer .performer-social-networks svg {
      margin-left: 15px; }
    @media only screen and (max-width: 768px) {
      .performer .performer-social-networks {
        padding-top: 20px;
        padding-right: 10px; } }
  .performer .performer-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 1; }
    .performer .performer-image img {
      width: 100%;
      height: 390px;
      object-fit: cover; }
  .performer .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 390px;
    z-index: 999; }
    .performer .overlay::after {
      content: " ";
      position: absolute;
      top: 40%;
      left: 0;
      width: 100%;
      height: 60%;
      background-image: linear-gradient(to top, #0B2B75, transparent); }
    .performer .overlay::before {
      content: " ";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: #0143a1;
      mix-blend-mode: color;
      backdrop-filter: blur(3px) brightness(0.5); }

.gallery-images {
  position: relative;
  display: flex;
  width: 100%;
  padding: 32px 0px 32px 28px;
  z-index: 999; }
  .gallery-images .gallery-image-wrap:hover .active {
    outline: 2px solid #D60018; }
  .gallery-images .gallery-image {
    width: 100%;
    height: 353px;
    margin-bottom: 35px; }
    .gallery-images .gallery-image img {
      width: 100%;
      height: 100%;
      object-fit: cover; }

.no-padding-col {
  padding: 0; }

.calendar-timeline {
  margin-top: 29px; }
  .calendar-timeline .timeline {
    position: relative;
    padding: 0 35px; }
    .calendar-timeline .timeline .line .months {
      display: flex;
      margin-top: -44px;
      margin-left: -52px;
      margin-right: -52px;
      overflow-x: auto;
      overflow-y: hidden; }
    .calendar-timeline .timeline .line hr {
      position: relative;
      height: 1px;
      width: 100%;
      background-color: #FFFFFF;
      z-index: 999; }
    .calendar-timeline .timeline .line .active-month {
      background-color: #D60018;
      border: none;
      border-radius: 17px;
      width: 100%;
      height: 100%;
      font-size: 23px;
      font-weight: 700;
      color: #FFFFFF;
      padding: 22px 30px 4px 30px;
      outline: none; }
      .calendar-timeline .timeline .line .active-month:focus {
        outline: none; }
      .calendar-timeline .timeline .line .active-month p {
        color: #FFFFFF;
        font-weight: 100;
        margin-bottom: 0; }
      .calendar-timeline .timeline .line .active-month svg {
        margin-top: -15px;
        margin-bottom: 5px; }
      .calendar-timeline .timeline .line .active-month .button-field {
        display: flex;
        flex-direction: column;
        align-items: center; }
    .calendar-timeline .timeline .line .month-btn {
      background-color: transparent;
      border: none;
      border-radius: 17px;
      width: 100%;
      height: 100%;
      font-size: 23px;
      font-weight: 100;
      color: #FFFFFF;
      padding: 22px 30px 4px 30px;
      outline: none; }
      .calendar-timeline .timeline .line .month-btn:focus {
        outline: none; }
      .calendar-timeline .timeline .line .month-btn p {
        color: transparent;
        font-weight: 100;
        margin-bottom: 0; }
      .calendar-timeline .timeline .line .month-btn svg {
        margin-bottom: 20px; }
      .calendar-timeline .timeline .line .month-btn .button-field {
        display: flex;
        flex-direction: column;
        align-items: center; }

.lightbox {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  height: 100vh;
  background-color: rgba(4, 43, 99, 0.73);
  z-index: 9999; }
  .lightbox .lightbox-item {
    padding: 100px 200px;
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column; }
    .lightbox .lightbox-item img {
      max-height: 85%;
      width: 100%;
      object-fit: cover;
      object-position: center; }
    .lightbox .lightbox-item .title {
      color: #FFFFFF;
      font-size: 18px;
      font-weight: 600;
      text-align: center;
      padding-top: 16px; }
    .lightbox .lightbox-item .lightbox-item-title {
      margin-bottom: 20px;
      display: flex;
      align-items: center; }
      .lightbox .lightbox-item .lightbox-item-title h3 {
        color: #FFFFFF;
        font-size: 36px;
        font-weight: 700; }
      .lightbox .lightbox-item .lightbox-item-title svg {
        position: absolute;
        left: 48%; }
      .lightbox .lightbox-item .lightbox-item-title h6 {
        color: transparent; }
      .lightbox .lightbox-item .lightbox-item-title .close {
        position: absolute;
        right: 232px;
        top: 116px;
        font-size: 48px;
        color: #FFFFFF;
        cursor: pointer;
        z-index: 99999; }
        .lightbox .lightbox-item .lightbox-item-title .close svg {
          fill: #FFFFFF; }
      @media only screen and (max-width: 768px) {
        .lightbox .lightbox-item .lightbox-item-title .close {
          margin-top: -40px;
          right: 46px; } }
    @media only screen and (max-width: 768px) {
      .lightbox .lightbox-item {
        padding: 150px 20px;
        width: 100%; } }
  .lightbox .carousel-control-next {
    position: absolute;
    top: unset;
    bottom: 87px;
    left: 50%;
    right: unset;
    margin-left: 12px;
    z-index: 99;
    width: 15px;
    opacity: 1; }
  .lightbox .carousel-control-prev {
    position: absolute;
    top: unset;
    bottom: 87px;
    left: 50%;
    right: unset;
    margin-left: -12px;
    z-index: 99;
    width: 15px;
    opacity: 1; }
  .lightbox .carousel-control-prev-icon {
    width: 9px;
    height: 15px;
    background-image: url(./assets/svg/left-chevron.svg);
    cursor: pointer; }
  .lightbox .carousel-control-next-icon {
    width: 9px;
    height: 15px;
    background-image: url(./assets/svg/right-chevron.svg);
    cursor: pointer; }
  .lightbox .carousel-indicators {
    position: absolute;
    top: 20px;
    left: 0;
    margin-top: 120px;
    margin-right: 14%;
    margin-left: 14%; }
    .lightbox .carousel-indicators li {
      width: 10px;
      height: 10px;
      margin-right: 5px;
      opacity: 1;
      border-radius: 50%; }
      .lightbox .carousel-indicators li:nth-last-child(3) {
        opacity: 0.7; }
      .lightbox .carousel-indicators li:nth-last-child(2) {
        opacity: 0.5; }
      .lightbox .carousel-indicators li:nth-last-child(1) {
        opacity: 0.3; }
    .lightbox .carousel-indicators .active {
      margin-top: -2px;
      width: 14px;
      height: 14px;
      opacity: 1 !important; }
    @media only screen and (max-width: 1360px) {
      .lightbox .carousel-indicators {
        display: none; } }

@media only screen and (max-width: 768px) {
  .stream-padding {
    padding-top: 40px; } }

.stream-content {
  margin-top: 40px;
  background-color: #0B2B75;
  width: 100%;
  max-height: 100%;
  position: relative;
  padding: 32px 29px 32px 29px;
  overflow-x: hidden; }
  @media only screen and (max-width: 768px) {
    .stream-content {
      padding: 32px 0px 32px 0px;
      margin-left: auto; } }
  .stream-content h1 {
    font-size: 57px;
    font-weight: 700;
    color: #FFFFFF;
    margin-bottom: 34px; }
  .stream-content p {
    font-size: 20px;
    font-weight: 100;
    color: #FFFFFF;
    margin-bottom: 47px; }
  .stream-content h2 {
    font-size: 42px;
    font-weight: 700;
    color: #FFFFFF; }
  .stream-content .stram-into {
    display: flex;
    flex-direction: column; }
  .stream-content .stream-button-field {
    position: absolute;
    top: 0;
    right: 90px; }
    .stream-content .stream-button-field .stream-btn {
      padding: 12px 20px;
      border: none;
      outline: none;
      color: #FFFFFF;
      font-size: 23px;
      font-weight: 700;
      background-color: #D60018;
      border-radius: 0 0 17px 17px; }
    .stream-content .stream-button-field:focus {
      outline: none; }
  .stream-content .stream-info h2 {
    margin-bottom: 21px; }
  .stream-content .stream-info p {
    margin-bottom: 21px; }
  .stream-content .stream-info .social-networks {
    display: flex; }
    .stream-content .stream-info .social-networks svg {
      margin: 0 11px; }
    .stream-content .stream-info .social-networks .first-icon {
      margin-left: 0; }
  .stream-content .stream-player {
    position: sticky; }
    .stream-content .stream-player iframe {
      width: 100%;
      height: 582px; }
    .stream-content .stream-player .play-stream {
      position: absolute;
      z-index: 99;
      top: 50%;
      left: 50%; }
    .stream-content .stream-player .title {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      background-color: #042B63;
      mix-blend-mode: multiply;
      z-index: 99;
      display: flex;
      padding: 11px 15px; }
      .stream-content .stream-player .title h6 {
        font-size: 20px;
        font-weight: 100;
        color: #FFFFFF;
        z-index: 999; }
      .stream-content .stream-player .title .time {
        position: absolute;
        left: 50%; }
  .stream-content .world-map {
    width: 100%; }
    .stream-content .world-map img {
      width: 100%; }
    .stream-content .world-map .map {
      height: 740px;
      width: 100%; }
  .stream-content .people-of-fm {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    position: sticky; }
    .stream-content .people-of-fm .gallery-field {
      position: relative;
      height: 145px;
      width: 20%;
      box-sizing: border-box; }
      @media only screen and (max-width: 768px) {
        .stream-content .people-of-fm .gallery-field {
          width: 100%; } }
      .stream-content .people-of-fm .gallery-field .active:hover {
        z-index: 9999;
        border: 2px solid #D60018;
        margin: -9px;
        width: calc(100% + 19px);
        position: relative; }
        .stream-content .people-of-fm .gallery-field .active:hover img {
          height: 155px; }
        .stream-content .people-of-fm .gallery-field .active:hover::after {
          content: none; }
      .stream-content .people-of-fm .gallery-field .active::after {
        content: ' ';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 99;
        background-color: rgba(4, 43, 99, 0.5);
        mix-blend-mode: multiply; }
      .stream-content .people-of-fm .gallery-field img {
        width: 100%;
        height: 145px;
        object-fit: cover; }

.social-media-content {
  margin-top: 40px; }
  .social-media-content .social-page-wrap {
    display: flex;
    position: sticky; }
  .social-media-content .social-network-page {
    width: 50%;
    height: 70vh;
    padding: 0;
    margin: 0;
    position: relative;
    border: 2px solid #D60018; }
    @media only screen and (max-width: 768px) {
      .social-media-content .social-network-page {
        width: 100%;
        height: 50vh;
        margin-top: 45px; } }
    .social-media-content .social-network-page .facebook-field-wrap {
      height: 100%;
      overflow-y: auto; }
      .social-media-content .social-network-page .facebook-field-wrap::-webkit-scrollbar {
        width: 19px;
        height: 180px; }
      .social-media-content .social-network-page .facebook-field-wrap::-webkit-scrollbar-thumb {
        height: 6px;
        border-radius: 15px;
        border: 6px solid rgba(0, 0, 0, 0);
        background-clip: padding-box;
        background-color: #D60018; }
      .social-media-content .social-network-page .facebook-field-wrap::-webkit-scrollbar-track {
        background: #FFFFFF; }
      .social-media-content .social-network-page .facebook-field-wrap::-webkit-scrollbar-button {
        width: 0;
        height: 0;
        display: none; }
      .social-media-content .social-network-page .facebook-field-wrap::-webkit-scrollbar-corner {
        background-color: transparent; }
    .social-media-content .social-network-page img {
      width: 100%; }
    .social-media-content .social-network-page .facebook-button-wrap {
      position: absolute;
      top: -48px;
      right: 65px; }
      @media only screen and (max-width: 768px) {
        .social-media-content .social-network-page .facebook-button-wrap {
          display: none; } }
    .social-media-content .social-network-page .instagram-button-wrap {
      position: absolute;
      top: -48px;
      left: 65px; }
      @media only screen and (max-width: 768px) {
        .social-media-content .social-network-page .instagram-button-wrap {
          display: none; } }
    .social-media-content .social-network-page .facebook-page-button {
      background-color: #D60018;
      border: none;
      outline: none;
      padding: 9px 29px;
      color: #FFFFFF;
      font-size: 18px;
      font-weight: 100;
      border-radius: 17px 17px 0 0; }
      .social-media-content .social-network-page .facebook-page-button svg {
        margin-right: 13px; }
  .social-media-content .social-page-buttons-wrap {
    display: none;
    position: absolute;
    width: 100%; }
    @media only screen and (max-width: 768px) {
      .social-media-content .social-page-buttons-wrap {
        display: block; } }
  .social-media-content .social-page-buttons {
    position: relative;
    display: flex;
    width: 100%;
    padding: 0 5%;
    justify-content: space-between; }
    .social-media-content .social-page-buttons .facebook-page-button-mob {
      background-color: #D60018;
      border: none;
      outline: none;
      padding: 9px 29px;
      color: #FFFFFF;
      font-size: 18px;
      font-weight: 100;
      border-radius: 17px 17px 0 0; }
      .social-media-content .social-page-buttons .facebook-page-button-mob svg {
        margin-right: 13px; }
    .social-media-content .social-page-buttons .facebook-page-button-mob-not-active {
      background-color: transparent;
      border: none;
      outline: none;
      padding: 9px 29px;
      color: #FFFFFF;
      font-size: 18px;
      font-weight: 100;
      border-radius: 17px 17px 0 0; }
      .social-media-content .social-page-buttons .facebook-page-button-mob-not-active svg {
        margin-right: 13px; }
  .social-media-content .social-network-page-not-active {
    width: 50%;
    height: 70vh;
    padding: 0;
    margin: 0;
    position: relative;
    border: none; }
    @media only screen and (max-width: 768px) {
      .social-media-content .social-network-page-not-active {
        display: none;
        width: 100%;
        height: 50vh;
        margin-top: 45px; } }
    .social-media-content .social-network-page-not-active .facebook-field-wrap {
      height: 100%;
      overflow-y: hidden; }
    .social-media-content .social-network-page-not-active img {
      width: 100%; }
    .social-media-content .social-network-page-not-active::after {
      content: ' ';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 999;
      background-color: rgba(4, 43, 99, 0.73);
      mix-blend-mode: multiply; }
    .social-media-content .social-network-page-not-active .facebook-button-wrap {
      position: absolute;
      top: -48px;
      right: 65px; }
      @media only screen and (max-width: 768px) {
        .social-media-content .social-network-page-not-active .facebook-button-wrap {
          display: none; } }
    .social-media-content .social-network-page-not-active .instagram-button-wrap {
      position: absolute;
      top: -48px;
      left: 65px; }
      @media only screen and (max-width: 768px) {
        .social-media-content .social-network-page-not-active .instagram-button-wrap {
          display: none; } }
    .social-media-content .social-network-page-not-active .facebook-page-button {
      background-color: transparent;
      border: none;
      outline: none;
      padding: 9px 29px;
      color: #FFFFFF;
      font-size: 18px;
      font-weight: 100;
      border-radius: 17px 17px 0 0; }
      .social-media-content .social-network-page-not-active .facebook-page-button svg {
        margin-right: 13px; }

.world-map-sidebar {
  background-color: transparent;
  backdrop-filter: blur(26px) brightness(0.8);
  height: 100%;
  width: 23%;
  padding: 0 15px;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 9999;
  -webkit-animation-name: example;
  /* Safari 4.0 - 8.0 */
  -webkit-animation-duration: 0.5s;
  /* Safari 4.0 - 8.0 */
  animation-name: example;
  animation-duration: 0.5s;
  display: flex;
  align-items: center;
  justify-content: center; }

@keyframes example {
  from {
    right: -100%; }
  to {
    right: 0; } }
  .world-map-sidebar .close-sidebar {
    position: absolute;
    top: 39px;
    left: -21px; }
    @media only screen and (max-width: 768px) {
      .world-map-sidebar .close-sidebar {
        left: 0; } }
  @media only screen and (max-width: 768px) {
    .world-map-sidebar {
      width: 100%; } }

.games-field {
  position: relative;
  width: 54%; }
  .games-field img {
    width: 100%; }
  @media only screen and (max-width: 768px) {
    .games-field {
      width: 100%; } }

.play-game {
  position: relative;
  width: 100%;
  height: 100%; }

.iframe {
  position: relative;
  width: 100%;
  height: 1080px;
  border: none; }

.iframe-social {
  position: relative;
  width: 100%;
  height: 100%;
  background-color: #f5f6f7; }

.play-game-btn {
  margin-left: 41px;
  z-index: 9999; }
  .play-game-btn .button {
    background-color: #034EA2;
    border: none;
    outline: none;
    border-radius: 23px;
    font-size: 23px;
    font-weight: 700;
    color: #FFFFFF;
    padding: 13px 39px; }
    .play-game-btn .button:focus {
      outline: none; }
  @media only screen and (max-width: 768px) {
    .play-game-btn {
      margin-left: 0px;
      margin-top: 20px; } }

.games-wrap {
  width: 100%;
  display: flex;
  margin-top: 22px;
  align-items: center; }
  @media only screen and (max-width: 768px) {
    .games-wrap {
      flex-direction: column; } }

.map-wrap {
  position: relative;
  height: 750px; }

.map {
  height: 750px; }

.google-map-marker {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-left: -16px;
  margin-top: -16px; }
  .google-map-marker img {
    width: 32px;
    height: 32px; }

.account-image-menu {
  width: 45px;
  height: 45px;
  border: none;
  background-color: transparent; }
  .account-image-menu img {
    border-radius: 50%;
    width: 100%;
    height: 100%;
    object-fit: cover; }

.image-picker {
  width: 100%;
  height: 100%;
  position: relative; }
  .image-picker:hover input[type=file] {
    display: block; }
  .image-picker input[type=file] {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    cursor: pointer;
    display: none; }
  .image-picker .no-image img {
    width: 100%;
    height: 100%;
    object-fit: cover; }
  .image-picker img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    object-position: center; }

.error-msg {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px; }
  .error-msg p {
    background-color: rgba(255, 255, 255, 0.4);
    padding: 8px 40px;
    border-radius: 25px;
    color: #D60018;
    font-weight: 500;
    font-size: 18px; }

.video-gallery iframe {
  width: 100%; }

.lightbox2 {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  height: 100vh;
  background-color: rgba(4, 43, 99, 0.73);
  z-index: 9999; }
  .lightbox2 .lightbox-item {
    padding: 100px 200px;
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column; }
    .lightbox2 .lightbox-item img {
      max-height: 85%;
      width: 100%;
      object-fit: cover;
      object-position: center; }
    .lightbox2 .lightbox-item .title {
      color: #FFFFFF;
      font-size: 18px;
      font-weight: 600;
      text-align: center;
      padding-top: 16px; }
    .lightbox2 .lightbox-item .lightbox-item-title {
      margin-bottom: 20px;
      display: flex;
      align-items: center; }
      .lightbox2 .lightbox-item .lightbox-item-title h3 {
        color: #FFFFFF;
        font-size: 36px;
        font-weight: 700; }
      .lightbox2 .lightbox-item .lightbox-item-title svg {
        position: absolute;
        left: 48%; }
      .lightbox2 .lightbox-item .lightbox-item-title h6 {
        color: transparent; }
      .lightbox2 .lightbox-item .lightbox-item-title .close {
        position: absolute;
        right: 232px;
        top: 90px;
        font-size: 48px;
        color: #FFFFFF;
        cursor: pointer;
        z-index: 99999; }
        .lightbox2 .lightbox-item .lightbox-item-title .close svg {
          fill: #FFFFFF; }
      @media only screen and (max-width: 768px) {
        .lightbox2 .lightbox-item .lightbox-item-title .close {
          margin-top: -40px;
          right: 46px; } }
    @media only screen and (max-width: 768px) {
      .lightbox2 .lightbox-item {
        padding: 150px 20px;
        width: 100%; } }
  .lightbox2 .carousel-control-next {
    position: absolute;
    top: unset;
    bottom: 87px;
    left: 50%;
    right: unset;
    margin-left: 12px;
    z-index: 99;
    width: 15px;
    opacity: 1; }
  .lightbox2 .carousel-control-prev {
    position: absolute;
    top: unset;
    bottom: 87px;
    left: 50%;
    right: unset;
    margin-left: -12px;
    z-index: 99;
    width: 15px;
    opacity: 1; }
  .lightbox2 .carousel-control-prev-icon {
    width: 9px;
    height: 15px;
    background-image: url(./assets/svg/left-chevron.svg);
    cursor: pointer; }
  .lightbox2 .carousel-control-next-icon {
    width: 9px;
    height: 15px;
    background-image: url(./assets/svg/right-chevron.svg);
    cursor: pointer; }
  .lightbox2 .carousel-indicators {
    position: absolute;
    top: 20px;
    left: 0;
    margin-top: 100px;
    margin-right: 14%;
    margin-left: 14%; }
    .lightbox2 .carousel-indicators li {
      width: 10px;
      height: 10px;
      margin-right: 5px;
      opacity: 1;
      border-radius: 50%; }
      .lightbox2 .carousel-indicators li:nth-last-child(3) {
        opacity: 0.7; }
      .lightbox2 .carousel-indicators li:nth-last-child(2) {
        opacity: 0.5; }
      .lightbox2 .carousel-indicators li:nth-last-child(1) {
        opacity: 0.3; }
    .lightbox2 .carousel-indicators .active {
      margin-top: -2px;
      width: 14px;
      height: 14px;
      opacity: 1 !important; }
    @media only screen and (max-width: 1360px) {
      .lightbox2 .carousel-indicators {
        display: none; } }

.games-result-page-wrap {
  position: relative;
  width: 96%;
  height: 74vh;
  overflow-y: scroll;
  padding: 50px;
  background-size: 100% 74vh; }
  .games-result-page-wrap table {
    position: relative;
    width: 100%;
    align-items: center;
    text-align: center; }
    .games-result-page-wrap table h3 {
      font-size: 42px;
      color: #FFFFFF;
      font-weight: 700;
      text-align: left; }
    .games-result-page-wrap table h4 {
      font-size: 28px;
      color: #FFFFFF;
      font-weight: 700; }
    .games-result-page-wrap table h5 {
      font-size: 24px;
      color: #FFFFFF;
      font-weight: 400; }
  .games-result-page-wrap .table-head-wrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    border-bottom: 2px solid #FFFFFF; }

.cookies {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: auto;
  background-color: rgba(255, 255, 255, 0.8);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 9999;
  overflow: hidden;
  padding-bottom: 10px; }
  .cookies .cookies-wrap {
    margin-right: 10px;
    display: flex;
    align-items: center; }
    .cookies .cookies-wrap .checkbox-wrap-v2 {
      margin-left: 10px; }
  .cookies p {
    font-size: 16px;
    font-weight: 400;
    color: #333333;
    margin-top: 10px;
    margin-right: 20px; }
    .cookies p a {
      font-weight: 700;
      color: #333333; }
  .cookies button {
    background-color: #333333;
    font-size: 16px;
    font-weight: 400;
    color: #FFFFFF;
    border-radius: 4px;
    border: 0;
    outline: none; }
    .cookies button:focus {
      outline: none; }
    @media only screen and (max-width: 767px) {
      .cookies button {
        font-size: 14px; } }
    @media only screen and (min-width: 767px) and (max-width: 1365px) {
      .cookies button {
        font-size: 14px; } }
  .cookies .disallow-btn {
    margin-left: 10px;
    background-color: transparent;
    border: 1px solid #333333;
    font-size: 16px;
    font-weight: 400;
    color: #333333;
    border-radius: 4px;
    outline: none; }
    .cookies .disallow-btn:focus {
      outline: none; }
    @media only screen and (max-width: 767px) {
      .cookies .disallow-btn {
        font-size: 14px; } }
    @media only screen and (min-width: 767px) and (max-width: 1365px) {
      .cookies .disallow-btn {
        font-size: 14px; } }
  .cookies .allow-btn {
    background-color: #333333;
    font-size: 16px;
    font-weight: 400;
    color: #FFFFFF;
    border-radius: 4px;
    border: 0;
    outline: none;
    margin-left: 15px; }
    .cookies .allow-btn:focus {
      outline: none; }
    @media only screen and (max-width: 767px) {
      .cookies .allow-btn {
        font-size: 14px; } }
    @media only screen and (min-width: 767px) and (max-width: 1365px) {
      .cookies .allow-btn {
        font-size: 14px; } }
  @media only screen and (max-width: 375px) {
    .cookies {
      height: 130px;
      padding: 10px 20px; }
      .cookies p {
        font-size: 10px;
        margin-right: 10px; } }
  @media only screen and (min-width: 376px) and (max-width: 499px) {
    .cookies {
      height: 110px;
      padding: 10px 20px; }
      .cookies p {
        font-size: 11px;
        margin-right: 10px; } }
  @media only screen and (min-width: 500px) and (max-width: 767px) {
    .cookies {
      height: 80px;
      padding: 10px 20px; }
      .cookies p {
        font-size: 12px;
        margin-right: 10px; } }
  @media only screen and (min-width: 767px) and (max-width: 1365px) {
    .cookies {
      height: 60px;
      padding: 10px 40px; }
      .cookies p {
        font-size: 12px; } }

.cookies-page {
  padding: 20px 20px;
  background-color: #0b2b75;
  margin-left: 16px;
  margin-right: 19px;
  margin-top: 40px; }
  .cookies-page h3 {
    font-size: 42px;
    font-weight: 700;
    color: #FFFFFF;
    margin-bottom: 31px; }
  .cookies-page h4 {
    font-size: 24px;
    font-weight: 600;
    color: #FFFFFF;
    margin-bottom: 20px; }
  .cookies-page p {
    font-size: 20px;
    font-weight: 100;
    color: #FFFFFF;
    margin-bottom: 20px; }

.social-box-container {
  display: flex; }
  @media (max-width: 1200px) {
    .social-box-container {
      flex-direction: column; } }

.social-box {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 33.33%;
  width: 33.39vw;
  height: 100vh; }
  @media (max-width: 1199px) {
    .social-box {
      width: 100%;
      height: 33.39vh; } }
  .social-box > a {
    position: absolute;
    top: 45px;
    left: 50%;
    transform: translate(-50%, 0); }
    @media (min-width: 992px) and (max-width: 1024px) {
      .social-box > a {
        top: 30px; } }
    @media (max-width: 991px) {
      .social-box > a {
        top: 0; } }
    .social-box > a svg {
      max-width: 100px; }
      @media (max-width: 768px) {
        .social-box > a svg {
          max-width: 70px; } }
      @media (max-width: 577px) {
        .social-box > a svg {
          max-width: 50px; } }
  .social-box-content {
    display: flex;
    align-items: center;
    justify-content: center; }
    .social-box-content img:hover {
      cursor: pointer; }
    @media (min-width: 1201px) and (max-width: 1550px) {
      .social-box-content img {
        max-width: 280px; } }
    @media (min-width: 993px) and (max-width: 1200px) {
      .social-box-content img {
        max-width: 200px;
        position: relative;
        top: 35px; } }
    @media (min-width: 557px) and (max-width: 992px) {
      .social-box-content img {
        max-width: 165px;
        position: relative;
        top: 30px; } }
    @media (max-width: 557px) {
      .social-box-content img {
        max-width: 100px;
        position: relative;
        top: 25px; } }

.box-1 {
  background-color: #0354A1; }

.box-2 {
  background: radial-gradient(circle at 30% 107%, #fdf497 0%, #fdf497 5%, #fd5949 45%, #d6249f 60%, #285AEB 90%); }

.box-3 {
  background-color: #4968B4; }

.checkbox-wrap-v2 {
  cursor: pointer;
  position: relative;
  display: flex;
  align-items: center; }
  .checkbox-wrap-v2 .checkbox-v2 {
    background-color: #E6EDF5;
    width: 20px;
    height: 20px;
    min-width: 20px;
    border-radius: 4px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 8px; }
    .checkbox-wrap-v2 .checkbox-v2::after {
      content: '';
      display: block;
      width: 0;
      height: 0;
      border-bottom: 0 solid #FFFFFF;
      border-right: 0 solid #FFFFFF;
      transform: rotate(45deg);
      transition: 0.3s all; }
    .checkbox-wrap-v2 .checkbox-v2.checkbox-v2-checked {
      background-color: #034EA2; }
      .checkbox-wrap-v2 .checkbox-v2.checkbox-v2-checked::after {
        content: '';
        display: block;
        width: 6px;
        height: 10px;
        border-bottom: 2px solid #FFFFFF;
        border-right: 2px solid #FFFFFF;
        transform: rotate(45deg); }
  .checkbox-wrap-v2.checkbox-wrap-v2-disabled .checkbox-v2 {
    background-color: #CCCCCC; }
  .checkbox-wrap-v2.checkbox-wrap-v2-disabled span {
    color: #CCCCCC; }

.sort-wrap {
  display: flex;
  align-items: center; }
  .sort-wrap label {
    margin-bottom: 0;
    margin-right: 10px;
    font-size: 18px;
    color: #FFFFFF; }
  .sort-wrap .form-control {
    width: 115px;
    background-color: transparent;
    color: #FFFFFF;
    font-weight: 18px; }
    .sort-wrap .form-control option {
      background-color: transparent !important;
      color: #333333; }
